.m-card {
  padding: 0.3rem 0.2rem 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
}

.m-card .m-card-usr {
  font-size: 0.24rem;
  color: #ccc;
  margin-bottom: 0.3rem;
}

.m-card .m-card-usr h4 {
  color: #000;
  font-size: 0.26rem;
}

.m-card .m-card-usr span + span {
  margin-left: 0.15rem;
}

.m-card .m-card-main {
  margin-left: 0.2rem;
}

.m-card .m-card-con {
  overflow: hidden;
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #666;
}

.m-card .m-card-con .image {
  margin-top: 0.12rem;
}

.m-card .m-card-con .image img {
  width: 5.18rem;
}

.m-card .m-card-body > h2 {
  font-size: 0.32rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.m-card .m-card-foot {
  margin-top: 0.2rem;
  margin-left: -0.2rem;
  margin-right: -0.2rem;
  padding: 0 0.2rem 0 1.2rem;
}

.m-card .m-card-foot .advertise-badge {
  border: 1px solid #ededed;
  padding: 0.02rem 0.08rem;
}

.m-card .m-card-tools {
  padding: 0.3rem 0;
  color: #b3b3b3;
  font-size: 0.24rem;
}

.m-card .m-card-tools a {
  color: inherit;
}

.m-card .m-card-tools a + a {
  margin-left: 0.6rem;
}

.m-card .m-card-tools span {
  margin-left: 0.1rem;
}